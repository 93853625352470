import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Container, Grid, Paper, Typography} from '@material-ui/core/'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ASKED_QUESTION, COMPARISON, PLANS} from '../../constants/pricing'
import Appbar from './components/Appbar'
import AppBarSmailSize from "./components/AppBarSmailSize";
import ComparisonTable from "./components/ComparisonTable";
import AskedQuestion from "./components/AskedQuestion";
import {Link} from "react-router-dom"
import _ from 'lodash'
import {axiosVApp} from "../../providers/axios"
import {useGlobal} from "../../actions"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  plan: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 320,
  },
  bestPlan: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: 320,
    borderStyle: "solid",
    borderColor: "#1b46b0",
    borderRadius: 0,
    borderTopStyle: "hidden",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: "auto",
  },
  askQuestion: {
    padding: theme.spacing(2),
    margin: "auto",
    height: "auto",
  },
  topFeature: {
    marginTop: 30,
    display: "block",
    textAlign: "center"
  },
  popular: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 18,
    borderStyle: "solid",
    borderBottomStyle: "hidden",
    borderColor: "#1b46b0",
    color: "white",
    padding: 5,
    backgroundColor: "#1b46b0",
    borderRadius: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  planContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    margin: "auto",
    marginLeft: 5,
    marginRight: 5,
    justifyContent: "space-between"
  },
  planItem: {
    display: "flex",
    minWidth: "30%",
    flexDirection: "column",
    "&:hover": {
      transform: "scale(1.05)",
    }
  },
  contactContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    margin: 12,
    marginTop: 20,
    marginBottom: 20,
    flexWrap: 'wrap'
  },
  contactContainerColumn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
    margin: 12,
    marginTop: 20,
    marginBottom: 20
  },
  contactContent:{
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: '0 20px',
    flexWrap: 'wrap'
  },
  contactLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    fontWeight: 550,
    fontSize: 18
  }

}));

export default function CenteredGrid() {
  const classes = useStyles();
  const { hostname } = window.location;
  const [globalState, globalActions] = useGlobal();
  const [screenSize, setScreenSize] = useState(undefined);
  const [activeSlideBar, setActiveSlideBar] = useState(false);
  const contactContainer = screenSize < 900 ? classes.contactContainerColumn : classes.contactContainer;

  useEffect(() => {
    axiosVApp
      .post("/domain-mapping/read", {
        loginDomain: hostname,
      })
      .then(({ data }) => {
        if (typeof data === "object" && data !== null) {
          if (data.rootOrganizationIds) {
            globalActions.setRootOrganizationIds(data.rootOrganizationIds);
          }
          if (data.vAppPageTitle) {
            document.title = data.vAppPageTitle;
          }
          if (data.faviconUrl) {
            document.querySelector("link[rel*='icon']").href = data.faviconUrl;
          }
          if (data.vAppForwardUrl) {
            globalActions.setReturnURL(data.vAppForwardUrl);
          }
        }
      })
      .catch(() => {
      });
  }, [])

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);

  }, [])

  useEffect(() => {
    if(screenSize < 1300) {
      setActiveSlideBar(true);
    } else {
      setActiveSlideBar(false);
    }
  }, [screenSize]);

  return (
    <Container maxWidth="xl" style={{margin: "auto", maxWidth: "70%"}}>
      {activeSlideBar ? <AppBarSmailSize/> : <Appbar/>}
      <Grid container spacing={3} style={{marginTop: 50}}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Get Started!</Typography>
            <Typography variant="h4">Compare plans to fit your operation</Typography>
            <Typography variant="h6" style={{color: "#1b46b0"}}>7-day free trial. No credit card required.</Typography>
          </Paper>
        </Grid>
        <Grid className={classes.planContainer} container xs={12} spacing={1}>
          {
            Object.keys(PLANS).map(plan => {
              const planDetail = _.get(PLANS, plan);
              const price = _.get(planDetail, 'price');
              const planName = _.get(planDetail, 'name');
              const className = planName !== "Premium" ? classes.plan : classes.bestPlan;
              return (
                  <Grid item sm={3} className={classes.planItem}>
                    {
                      planName === "Premium" ?
                          <Paper className={classes.popular}>Most Popular</Paper>
                          : ""
                    }
                    <Paper className={className}>
                      <Typography variant="h4" gutterBottom style={{ fontWeight: 600, height: 52, display: "flex",  justifyContent:"center", flexDirection: "row", alignItems: "center"}}>
                        {planName}
                      </Typography>
                      <Typography variant='caption' gutterBottom>{_.get(planDetail, 'fitWith')}</Typography>
                      <Typography variant="h4" gutterBottom display='block'>{price ? `$${price}` : "Contact Us" }</Typography>
                      <Typography variant='caption' gutterBottom paragraph>{_.get(planDetail, 'explain')}</Typography>
                      {/* <Button variant="contained" color="primary" fullWidth></Button> */}
                      {planName !== "Enterprise"
                          ? <Link to={"/sign-up"} style={{ color: "#2196f3" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                  globalActions.setPlan(planName)
                                }}
                            >
                              {_.get(planDetail, 'tryButtonText')}</Button>
                          </Link>
                          : <a href={"https://www.abivin.com/sign-up"} target={"_blank"}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                  globalActions.setPlan(planName)
                                }}
                            >
                              {_.get(planDetail, 'tryButtonText')}</Button>
                          </a>
                      }
                      <Typography className={classes.topFeature} >{_.get(planDetail, 'topFeatures')}</Typography>
                    </Paper>
                  </Grid>
              )
            })
          }
        </Grid>
        <Grid container xs={12} spacing={0}>
          <Paper className={contactContainer}>
            <Grid item xs={12} className={classes.contactContent}>
            <Typography variant='h4' style={ {fontWeight: 600,marginBottom: '16px', height: 52, display: "flex",  justifyContent:"center", flexDirection: "row", alignItems: "center"}}>Enterprise</Typography>
            </Grid>
            <Grid container item xs={12} className={classes.contactLink}>
              <Grid item xs={10} className={classes.contactContent}>
                <Typography >If you have a large flee in need of specialized features like SAML-based single sign-on (SSO), a sandbox environment for testing and training, priority support, and/or custom branding, let's chat!</Typography>
              </Grid>
              <Grid xs={2}>
                <a href={"https://www.abivin.com/sign-up"} target={"_blank"}>
                  Contact Us &#8594;
                </a>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper1}>
            <Typography variant="h4" style={{fontWeight: 600, marginBottom: 30}}>Full Plan Comparison</Typography>
            {COMPARISON.map((key) => (
                <ComparisonTable category={key.name} features={key.features}/>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.askQuestion}>
            <Typography variant="h4" style={{fontWeight: 500, marginBottom: 40, marginTop: 25, textAlign: "center", color: "#1b64b0"}}>Frequently asked questions</Typography>
            {
              ASKED_QUESTION.map((value) => (
                  <AskedQuestion ask={value.ask} answer={value.answer} href={value.href}/>
              ))
            }
            <a href={"https://www.abivin.com/faq"} style={{marginTop: 30, color: "#1b64b0", paddingLeft: "16%", fontWeight: 500, fontFamily: "Roboto" }} target={"_blank"}>
              View all FAQs &#8594;
            </a>
            <Typography variant="h5" style={{fontWeight: 500, marginBottom: 15, textAlign: "center", color: "#1b64b0", marginTop: 60}}>Ready to get started ?</Typography>
            <div style={{textAlign: "center", marginBottom: 40, fontFamily: "Roboto"}}>
              Have any question? Call us at <span style={{color: "#1b64b0"}}>+84 862 282 166</span> or email <span style={{color: "#1b64b0"}}>info@abvin.com</span>
            </div>
            <div style={{textAlign: "center", marginBottom: 40, fontFamily: "Roboto"}}>
              <Link to={"/sign-up"} style={{ color: "#2196f3", margin: "auto" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      globalActions.setPlan("Basic")
                    }}
                >
                  Start Free Trail
                </Button>
              </Link>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
